.theme-light {

    body {
        color: var(--blackColor);
        background-color: var(--whiteColor);
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: var(--blackColor);
    }
    a {
        color: var(--blackColor);
    
        &:hover {
            color: var(--mainColor);
        }
    }
    p {
        color: #666666;
    }
    .default-btn {
        color: var(--whiteColor);
        
        &:hover {
            color: var(--mainColor);
        }
    }
    .optional-btn {
        color: var(--whiteColor);
        
        &:hover {
            color: var(--mainColor);
        }
    }
    .bg-1b060a {
        background-color: #f9f9f9;
    }
    .form-control {
        color: var(--blackColor);
        background-color: #f5f5f5 !important;
        
        &::placeholder {
            color: #666666
        }
        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }

    .zelda-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    a {
                        color: var(--whiteColor);
                        
                        &:hover, &.active {
                            color: var(--blackColor);
                            background-color: var(--whiteColor);
                        }
                    }
                    &:hover, &.active {
                        a {
                            color: var(--blackColor);
                            background-color: var(--whiteColor);
                        }
                    }
                    .dropdown-menu {
                        background-color: var(--whiteColor);
                        box-shadow: rgba(4, 4, 5, .20) 0px 7px 36px -8px;
                        
                        li {
                            a {
                                color: var(--blackColor);
                                
                                i {
                                    color: var(--blackColor);
                                }
                                &::before {
                                    color: var(--blackColor);
                                }
                                &:hover, &.active {
                                    color: var(--mainColor);
    
                                    &::before {
                                        color: var(--mainColor);
                                    }
                                }
                            }
                            .dropdown-menu {
                                li {
                                    a {
                                        color: var(--blackColor);
    
                                        &:hover, &.active {
                                            color: var(--mainColor);
                                        }
                                    }
                                    .dropdown-menu {
                                        li {
                                            a {
                                                color: var(--blackColor);
            
                                                &:hover, &.active {
                                                    color: var(--mainColor);
                                                }
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        color: var(--blackColor);
                    
                                                        &:hover, &.active {
                                                            color: var(--mainColor);
                                                        }
                                                    }
                                                    .dropdown-menu {
                                                        li {
                                                            a {
                                                                color: var(--blackColor);
                            
                                                                &:hover, &.active {
                                                                    color: var(--mainColor);
                                                                }
                                                            }
                                                            .dropdown-menu {
                                                                li {
                                                                    a {
                                                                        color: var(--blackColor);
                                    
                                                                        &:hover, &.active {
                                                                            color: var(--mainColor);
                                                                        }
                                                                    }
                                                                    .dropdown-menu {
                                                                        li {
                                                                            a {
                                                                                color: var(--blackColor);
                                            
                                                                                &:hover, &.active {
                                                                                    color: var(--mainColor);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.active {
                                                                        a {
                                                                            color: var(--mainColor);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.active {
                                                                a {
                                                                    color: var(--mainColor);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &.active {
                                                        a {
                                                            color: var(--mainColor);
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                a {
                                                    color: var(--mainColor);
                                                }
                                            }
                                        }
                                    }
                                    &.active {
                                        a {
                                            color: var(--mainColor);
                                        }
                                    }
                                }
                            }
                            &.active {
                                a {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                .option-item {
                    color: var(--whiteColor);
                }
            }
        }
    }
    .others-option-for-responsive {
        .option-inner {
            background-color: var(--whiteColor);
        }
    }

    .main-banner-content {
        h6 {
            color: var(--whiteColor);
        }
    }
    .banner-video-box {
        .content {
            .title {
                color: var(--whiteColor);
            }
        }
    }
    .banner-video-slides {
        &.owl-theme {
            .owl-dots {
                position: relative;
                top: -13px;
            }
        }
    }
    .hero-banner-content {
        &::before {
            background-color: var(--whiteColor);
        }
        .btn-box {
            .video-btn {
                color: var(--blackColor);
                
                i {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
    .banner-content {
        h6 {
            color: var(--whiteColor);
        }
    }
    .banner-wrapper-content {
        h1 {
            color: var(--whiteColor);
        }
    }

    .matches-area {
        &.bg-image {
            &::before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                z-index: -1;
                position: absolute;
                background-color: #f4f4f4;
            }
        }
    }
    .single-matches-box {
        .matches-team {
            .content {
                h3 {
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    background: {
                        color: var(--whiteColor);
                        image: unset;
                    };
                }
                .watch-list {
                    li {
                        a {
                            color: #666666;
    
                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                    }
                }
            }
            &.right-image {
                .content {
                    h3 {
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                        background: {
                            color: var(--whiteColor);
                            image: unset;
                        };
                    }
                }
            }
        }
    }
    .matches-tabs {
        .nav-tabs {
            background-color: var(--whiteColor);
    
            .nav-item {
                .nav-link {
                    color: var(--blackColor);
                    
                    &.active {
                        color: var(--whiteColor) !important;
                    }
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .tab-content {
            background-color: rgba(255, 255, 255, .60);
        }
    }
    .shape1, .shape2, .shape3, .shape4 {
        opacity: .05;
    }

    .single-services-box {
        .icon {
            color: var(--blackColor);
        }
        &:hover {
            .icon {
                color: var(--mainColor);
            }
        }
    }

    .single-popular-leagues-box {
        background-color: #f5f5f5;
    }

    .single-tournaments-box {
        background-color: #f5f5f5;
    
        .tournaments-content {
            .content {
                .date {
                    sup {
                        color: var(--blackColor);
                    }
                    sub {
                        color: var(--blackColor);
                    }
                }
            }
        }
    }

    .partner-area {
        .section-title {
            .sub-title {
                color: var(--mainColor);
            }
        }
        &.bg-image {
            background: #f9f9f9;
        }
    }
    .single-partner-item {
        img {
            filter: invert(1);
        }
    }
    .partner-shape1 {
        opacity: .03;
    }

    .subscribe-inner {
        h2 {
            color: var(--whiteColor);
        }
    }

    .single-products-item {
        .products-image {
            .bg-image {
                opacity: .07;
            }
        }
        .sale {
            color: var(--whiteColor);
        }
        .hot {
            color: var(--whiteColor);
        }
    }
    .single-products-box {
        .sale {
            color: var(--whiteColor);
        }
        .hot {
            color: var(--whiteColor);
        }
    }
    .products-slides {
        &.owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: #cccccc;
                    }
                    &:hover, &.active {
                        span {
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
    .zelda-grid-sorting {
        .result-count {
            p {
                .count {
                    color: var(--blackColor);
                }
            }
        }
        .ordering {
            select {
                color: var(--blackColor);
                border-color: #eeeeee;
                
                option {
                    color: var(--blackColor);
                }
            }
        }
    }

    .pagination-area {
        .page-numbers {
            background-color: var(--blackColor);
            color: var(--whiteColor);
            
            &:hover, &.current {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }

    .cart-table {
        table {
            thead {
                tr {
                    border-color: #eeeeee;
    
                    th {
                        color: var(--blackColor);
                    }
                }
            }
            tbody {
                tr {
                    border-color: #eeeeee;
                    
                    td {
                        color: var(--blackColor);
                        border: {
                            color: #eeeeee;
                        };
                        &.product-quantity {
                            .input-counter {
                                span {
                                    background-color: #f5f5f5;
                                    color: var(--blackColor);
                    
                                    &:hover {
                                        color: var(--whiteColor);
                                        background-color: var(--mainColor);
                                    }
                                }
                                input {
                                    color: var(--blackColor);
                                    background-color: #f5f5f5;

                                    &::placeholder {
                                        color: var(--blackColor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        .shopping-coupon-code {
            button {
                background: var(--blackColor);
                color: var(--whiteColor);
                
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    .cart-totals {
        background: #f5f5f5;
        
        ul {
            li {
                border-color: #eeeeee;
                color: var(--blackColor);
                
                &:last-child {
                    span {
                        color: var(--blackColor);
                    }
                }
                span {
                    color: var(--blackColor);
                }
            }
        }
    }

    .user-actions {
        background-color: var(--whiteColor);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .billing-details {
        .title {
            border-bottom-color: #eeeeee;
        }
        .form-group {
            select {
                background-color: #f5f5f5;
                color: var(--blackColor);
            }
            .form-control {
                color: var(--blackColor);
            }
        }
        .form-check {
            .form-check-label {
                color: var(--blackColor);
            }
        }
    }
    .order-details {
        .title {
            border-bottom-color: #eeeeee;
        }
        .order-table {
            table {
                thead {
                    tr {
                        border-color: #eeeeee;
                        
                        th {
                            border-color: #eeeeee;
                            color: var(--blackColor);
                        }
                    }
                }
                tbody {
                    border-color: #eeeeee;
                    
                    tr {
                        td {
                            color: #666666;
                            border-color: #eeeeee;
                            
                            &.product-name {
                                a {
                                    color: #666666;
    
                                    &:hover {
                                        color: var(--mainColor);
                                    }
                                }
                            }
                            &.order-subtotal, &.order-shipping , &.total-price {
                                span {
                                    color: var(--blackColor);
                                }
                            }
                            &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                                color: var(--blackColor);
                            }
                        }
                    }
                }
            }
        }
        .payment-box {
            background-color: var(--whiteColor);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            
            .payment-method {
                p {
                    [type="radio"] {
                        &:checked, &:not(:checked) {
                            + label {
                                color: var(--blackColor);
                            }
                        }
                    }
                }
            }
        }
    }

    .products-details-desc {
        .price {
            .old-price {
                color: #666666;
            }
        }
        .products-review {
            .rating-count {
                color: var(--blackColor);
    
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
        p {
            color: #666666;
        }
        .products-meta {
            span {
                color: var(--blackColor);
                
                span {
                    &.in-stock {
                        color: var(--blackColor);
                    }
                }
                a {
                    color: var(--blackColor);
    
                    &:hover, &:focus {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .products-add-to-cart {
            .input-counter {
                span {
                    background-color: #f5f5f5;
                    color: var(--blackColor);
    
                    &:hover {
                        color: var(--whiteColor);
                        background-color: var(--mainColor);
                    }
                }
                input {
                    background-color: #f5f5f5;
                    color: var(--blackColor);

                    &::placeholder {
                        color: var(--blackColor);
                    }
                }
            }
        }
    }
    .products-details-tabs {
        .nav {
            border-bottom-color: #eeeeee;
    
            .nav-item {
                .nav-link {
                    color: var(--blackColor);
                    border-bottom-color: #eeeeee;

                    &:hover, &.active {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                p {
                    color: #666666;
                }
                ul {
                    li {
                        color: #666666;

                        &::before {
                            background-color: var(--blackColor);
                        }
                    }
                }
                .products-reviews {
                    .rating-count {
                        span {
                            color: var(--blackColor);
                        }
                    }
                }
                .products-review-comments {
                    h3 {
                        border-bottom-color: #eeeeee;
                    }
                    .user-review {
                        border-bottom-color: #eeeeee;
                
                        .review-rating {
                            span {
                                color: var(--blackColor);
                            }
                        }
                    }
                }
                .review-form-wrapper {
                    form {
                        .comment-form-cookies-consent {
                            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                                color: var(--blackColor);
                            }
                        }
                    }
                }
            }
        }
    }

    .login-form {
        background-color: var(--whiteColor);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
        form {
            .form-group {
                .form-control {
                    background-color: #f5f5f5 !important;
                }
            }
            .remember-me-wrap {
                [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                    color: var(--blackColor);
                }
            }
            button {
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor)
                }
            }
        }
    }
    .register-form {
        form {
            button {
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor)
                }
            }
        }
    }

    .page-title-content {
        h1 {
            color: var(--whiteColor);
        }
        .matches-team, .single-matches-box {
            .content {
                .watch-list {
                    li {
                        a {
                            color: #bcb6b8;

                            &:hover {
                                color: var(--whiteColor);
                            }
                        }
                    }
                }
            }
            .matches-result {
                ul {
                    li {
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }

    .match-details-desc {
        .article-content {
            p {
                color: #666666;
            }
        }
        .article-footer {
            border-color: #eeeeee;
        }
    }

    .single-blog-post-item {
        .post-content {
            background: {
                image: unset;
                color: #f9f9f9;
            };
        }
    }
    .blog-slides, .blog-slides-two {
        &.owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: #cccccc;
                    }
                    &:hover, &.active {
                        span {
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
    .single-blog-post-box {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: var(--whiteColor);
    }

    .blog-details-desc {
        .article-image-slides {
            &.owl-theme {
                .owl-nav {
                    margin-top: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: var(--transition);
        
                    [class*=owl-] {
                        color: var(--blackColor);
                        font-size: 18px;
                        margin: 0;
                        padding: 0;
                        background: var(--whiteColor);
                        width: 45px;
                        height: 45px;
                        line-height: 40px;
                        position: absolute;
                        left: 20px;
                        transition: var(--transition);
                        top: 50%;
                        transform: translateY(-50%);
                        padding: {
                            left: 5px !important;
                        };
                        border: {
                            radius: 50%;
                            style: solid;
                            color: var(--whiteColor);
                            width: 1.5px;
                        };
                        &.owl-next {
                            left: auto;
                            right: 20px;
                            padding: {
                                left: 3px !important;
                            };
                        }
                        &:hover {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                            border-color: var(--mainColor);
                        }
                    }
                }
                &:hover {
                    .owl-nav {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        .article-content {
            .entry-meta {
                ul {
                    li {
                        border-color: #eeeeee;
                        
                        i {
                            color: #666666;
                        }
                    }
                }
            }
            p {
                color: #666666;
            }
            .features-list {
                li {
                    color: #666666;
                }
            }
        }
        .article-footer {
            border-top-color: #eeeeee;
        }
        .article-author {
            background: #f9f9f9;
    
            .author-profile {
                .author-profile-title {
                    span {
                        color: var(--blackColor);
                    }
                }
            }
        }
    }
    .zelda-post-navigation {
        border: {
            top-color: #eeeeee;
            bottom-color: #eeeeee;
        };
    }
    .prev-link-wrapper {
        .meta-wrapper {
            color: var(--blackColor);
        }
    }
    .next-link-wrapper {
        .meta-wrapper {
            color: var(--blackColor);
        }
    }
    blockquote, .blockquote {
        background-color: #f5f5f5;
        
        p {
            color: var(--blackColor) !important;
        }
    }
    .comments-area {
        .comment-body {
            border-bottom-color: #eeeeee;
            color: var(--blackColor);
    
            .reply {
                a {
                    border-color: #eeeeee;
                    color: var(--blackColor);

                    &:hover {
                        color: var(--whiteColor);
                        border-color: var(--mainColor);
                    }
                }
            }
        }
        .comment-metadata {
            color: var(--blackColor);
            a {
                color: var(--blackColor);
    
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
        .comment-content {
            p {
                color: #666666;
            }
        }
        .comment-respond {
            input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
                background-color: #f5f5f5;
                color: var(--blackColor);
                
                &::placeholder {
                    color: #666666
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .comment-form-cookies-consent {
                label {
                    color: var(--blackColor);
                }
            }
        }
    }

    .team-details-desc {
        .team-meta {
            li {
                color: #666666;
                
                .icon {
                    color: var(--blackColor);
                }
                span {
                    color: var(--blackColor);
                }
            }
        }
    }

    .player-details-desc {
        .player-meta {
            li {
                color: #666666;
                
                .icon {
                    color: var(--blackColor);
                }
                span {
                    color: var(--blackColor);
                }
            }
        }
    }

    .widget-area {
        .widget {
            .widget-title {
                border-bottom-color: #eeeeee;
            }
        }
        .widget_search {
            form {
                .search-field {
                    color: var(--blackColor);
                    background-color: #f5f5f5;
                    
                    &::placeholder {
                        color: #666666;
                    }
                    &:focus {
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
                button {
                    color: var(--blackColor);

                    &:hover, &:focus {
                        color: var(--whiteColor);
                    }
                }
            }
        }
        .widget_zelda_posts_thumb {
            .item {
                .info {
                    span {
                        color: var(--blackColor);
                    }
                }
            }
        }
        .widget_match_list {
            background-color: var(--whiteColor);
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
            .single-match-list {
                border-bottom-color: #eeeeee;
            }
        }
        .widget_instagram {
            ul {
                li {
                    .box {
                        i {
                            color: var(--whiteColor);
                        }
                    }
                }
            }
        }
    }

    .single-magazine-box {
        &::before {
            background: var(--whiteColor);
            background: linear-gradient(rgba(253, 49, 71, 0), var(--whiteColor));
        }
        .content {
            .meta {
                li {
                    &::before {
                        background-color: var(--blackColor);
                    }
                }
            }
        }
        &:hover {
            .content {
                h3 {
                    color: var(--whiteColor);
                }
            }
        }
    }

    .single-stream-schedule-box {
        border-color: #eeeeee;
        
        .content {
            border-color: #eeeeee;
        }
    }

    .featured-games-inner {
        background-color: #f7f7f7;
    }
    .featured-games-content {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            .owl-dots {
                .owl-dot {
                    &:hover, &.active {
                        span {
                            border-color: var(--mainColor);
    
                            &::before {
                                background-color: var(--mainColor);
                            }
                        }
                    }
                }
            }
        }
    }

    .single-games-box {
        .content {
            h3 {
                color: var(--whiteColor);
            }
        }
    }
    .games-slides {
        &.owl-theme {
            .owl-dots {
                .owl-dot {
                    &:hover, &.active {
                        span {
                            border-color: var(--mainColor);
        
                            &::before {
                                background-color: var(--mainColor);
                            }
                        }
                    }
                }
            }
        }
    }

    .faq-accordion-tab {
        .tabs {
            li {
                a {
                    color: var(--blackColor);
                    background-color: #f5f5f5;
                    
                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
                &.current {
                    a {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-item {
                background: #f5f5f5;
                border: none;
            }
            .accordion-title {
                color: var(--blackColor);
            }
        }
    }

    .single-top-team-box {
        .bg-image {
            opacity: .05;
        }
        .content {
            ul {
                li {
                    a {
                        color: var(--blackColor);
    
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
    .top-team-slides {
        &.owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: #cccccc;
                    }
                    &:hover, &.active {
                        span {
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
    .single-team-member {
        .content {
            background-color: var(--whiteColor);
        }
    }
    .single-top-team-item {
        background-color: #f5f5f5;

        ul {
            li {
                a {
                    color: #666666;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .coming-soon-content {
        background-color: var(--whiteColor);
        
        #timer {
            div {
                background-color: #f5f5f5;
            }
        }
        form {
            .form-group {
                .input-newsletter {
                    background-color: #f5f5f5;
                    color: var(--blackColor);
                    
                    &::placeholder {
                        color: #666666;
                    }
                    &:focus {
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
            }
        }
    }

    .our-experts-content {
        .content {
            h2 {
                color: var(--whiteColor);
            }
        }
    }

    .live-stream-area {
        .section-title {
            h2 {
                color: var(--whiteColor);
            }
        }
        &.bg-color {
            background-color: var(--whiteColor);
            
            .section-title {
                h2 {
                    color: var(--blackColor);
                }
            }
        }
    }
    .single-live-stream-box {
        .content {
            background-color: var(--whiteColor);
        }
    }
    .single-live-stream-item {
        &::before {
            background: var(--whiteColor);
            background: linear-gradient(rgba(253, 49, 71, 0), var(--whiteColor));
        }
        .content {
            .meta {
                li {
                    &::before {
                        background-color: var(--blackColor);
                    }
                }
            }
        }
        .video-btn {
            color: var(--whiteColor);
        }
        &:hover {
            h3 {
                color: var(--whiteColor);
            }
            .video-btn {
                background-color: var(--whiteColor);
                color: var(--mainColor);
            }
        }
    }

    .awards-content {
        h2 {
            color: var(--whiteColor);
        }
    }

    .single-social-box {
        .content {
            transition: var(--transition);
        }
        .shape {
            img {
                &:nth-child(1) {
                    opacity: .1;
                }
            }
        }
        &:hover {
            .content {
                color: var(--whiteColor);
            }
        }
    }

    .single-feedback-item {
        background-color: #f7f7f7;
    
        p {
            color: var(--blackColor);
        }
        &:hover {
            p {
                color: var(--blackColor);
            }
        }
    }

    .history-content {
        h2 {
            color: var(--whiteColor);
        }
    }

    .contact-info {
        ul {
            li {
                .icon {
                    background-color: #f5f5f5;
                    color: var(--blackColor);
                }
                &:hover {
                    .icon {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
    .contact-form {
        h2 {
            color: var(--whiteColor);
        }
        p {
            color: var(--whiteColor);
        }
    }

    .tournament-details-desc {
        .tournament-meta {
            li {
                color: #666666;
                
                .icon {
                    color: var(--blackColor);
                }
                span {
                    color: var(--blackColor);
                }
            }
        }
    }

    .footer-content {
        .footer-menu {
            li {
                a {
                    color: var(--blackColor);
                    
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .copyright-area {
        border-color: #eeeeee;
        
        .lang-switcher {
            select {
                color: var(--blackColor);
                border-color: #eeeeee;
                
                option {
                    color: var(--blackColor);
                }
            }
        }
    }
    .footer-area-bg-image {
        z-index: 1;
        background: unset;
        position: relative;

        &::before {
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            content: '';
            height: 85%;
            position: absolute;
            background-color: #f5f5f5;
        }
    }
    .footer-style-two {
        background-color: #f8f8f8;
    }
    .footer-map {
        opacity: .40;
    }
    .single-footer-widget {
        p {
            color: #666666;
        }
        .footer-links-list {
            li {
                color: #666666;
    
                a {
                    color: #666666;
                    
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .footer-contact-info {
            li {
                color: #666666;
    
                a {
                    color: #666666;
                    
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .footer-bottom-area {
        border-top-color: #eeeeee;
    }

    .buy-now-btn {
        color: var(--whiteColor);

        &:hover {
            color: var(--whiteColor);
        }
    }

    .modal {
        &.right {
            .modal-content {
                background-color: var(--whiteColor);
    
                button {
                    &.close {
                        color: var(--blackColor);
                    }
                }
            }
        }
    }
    .sidebarModal {
        &.modal {
            .modal-body {
                .instagram-list {
                    .box {
                        i {
                            color: var(--whiteColor);
                        }
                    }
                }
                .social-list {
                    li {
                        a {
                            color: var(--blackColor);
    
                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 767px){

    .theme-light {
        .banner-video-slides {
            &.owl-theme {
                .owl-dots {
                    top: 0;
                }
            }
        }
    }

}