/*
MeanMenu 2.0.7
--------
To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)
*/
a.meanmenu-reveal {
  display: none;
}
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: absolute;
  background: transparent;
  padding: 0 0 0;
  z-index: 999;
}
.mean-container button.meanmenu-reveal {
  width: 35px;
  height: 30px;
  position: absolute;
  right: 0;
  cursor: pointer;
  color: var(--whiteColor);
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-weight: 700;
  padding-top: 3px;
  background: none;
  border: none;
}
.mean-container button.meanmenu-reveal span {
  display: block;
  background: var(--whiteColor);
  height: 4px;
  margin-top: -20px;
  border-radius: 3px;
  position: relative;
  top: 8px;
}
.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: var(--whiteColor);
  margin-top: 62px;
}
.mean-container .mean-nav ul {
  padding: 0;
  font-size: 20px;
  margin: 0;
  width: 100%;
  border: none;
  list-style-type: none;
  text-transform: uppercase;
}
.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}
.mean-container .mean-nav ul li a i {
  display: none;
}
.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 12px 5% 7px;
  margin: 0;
  text-align: left;
  color: var(--blackColor);
  border-top: 1px solid #dbeefd;
  text-decoration: none;
}
.mean-container .mean-nav ul li a.active {
  color: var(--mainColor);
}
.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 12px 10% 7px;
  color: var(--blackColor);
  border-top: 1px solid #dbeefd;
  opacity: 1;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
  font-size: 18px;
}
.mean-container .mean-nav ul li.mean-last a {
  margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15% 13px;
}
.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20% 13px;
}
.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25% 13px;
}
.mean-container .mean-nav ul li a:hover {
  background: var(--blackColor);
  background: rgba(255, 255, 255, 0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 0;
  width: 100%;
  height: 24px;
  font-size: 25px !important;
  padding: 11px !important;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 500;
  background: transparent;
  border: none !important;
}
.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}
.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.mean-remove {
  display: none !important;
}
